import base from './base'

export const breadcrumbs = props => base({
	name: 'breadcrumbs',
	getAttributes: () => ({
		'@context': 'http://schema.org',
		'@type': 'BreadcrumbList',
		'itemListElement': props.crumbs.map(crumb => ({
			'@type': 'ListItem',
			'position': crumb.position,
			'name': crumb.name,
			'item': props.siteUrl + crumb.link
		}))
	})
})
